// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$blue: #2460b9 ;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: red;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;

// Scrren Size
$xs : 375px;
$sm : 576px;
$md : 768px;
$lg : 992px;
$xlg : 1024px;
$xxlg : 1148px;
$xxxlg : 1366px;
$xxxxlg : 1440px;
