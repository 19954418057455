footer{
    background-color: $blue;
    .footer-wrapper{
        max-width: 1366px;
        color: white;
        padding: 5rem 5rem 0;
        text-align: center;
        @media screen and (min-width:$lg){
            text-align: left;
        }
        .footer{
            @media screen and (min-width:$lg){                
                &:first-child,&:nth-child(2){
                    // border-right: 1px solid white;
                }
            }
            line-height: 2rem;
            padding: 1rem;
            ul{
                list-style-type: none;
                font-size: 1rem;
                padding-left: 0;
                padding-top: 0.5rem;
                
                a{
                    color: #fff;
                    text-decoration: none;
                    &:hover{
                        color: rgba(255, 255, 255, 0.596);
                    }
                }
            }
            #contact-info, #links, #information-officer{
                display: flex;
                flex-direction: column;
                h2{
                    font-size: 1.5rem;
                    align-self: center;
                    white-space: nowrap;
                }
                ul{
                    align-self: center;
                    li{
                        white-space: nowrap;
                    }
                }
            }
        }
    }
}
.copyright{
    // max-width: 1366px;
    background-color: $blue;
    color: white;
    font-size: 1rem;
    padding: 2.5rem;
    text-align: center;
}