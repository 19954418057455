.swiper-container {
    width: 100%;
    border: 0.2rem solid $blue;
}

.swiper-slide {
    background-size: cover;
    background-position: 50%;
    height: 430px;
    max-height: 430px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

/* Slide captions */
.slide-captions {
	position: absolute;
	top: 92%;
	left: 1.5%;
	color: #2460B9;
	z-index: 999;
	transform: translateY(-50%);
    background: #ffffffb3;
    padding: 5px 10px;
    border-radius: 3px;
}