.element-news-and-downloads{
    width: 100%;
    height: 100%;
    
    .card-header{
        border: 1px solid $blue;
        background-color: $blue;
        border-radius: 0;
        padding: 0;
        height: 2.5rem;

        nav{
            height: 100%;
            .nav{
                &.nav-tabs{
                    .nav-link{
                        &.active{
                            border-color: transparent;
                            background: #fff;
                            color: $blue;
                        }
                    }
                }
                height: 100%;
                a{
                    border-right: 1px solid rgba(173, 169, 169, 0.507);
                    font-size: 0.8rem;
                    font-weight: 700;
                    color: white;
                    &.active{
                        color: $blue;
                    }
                    @media screen and (min-width:$md) {
                        font-size: 1.1rem;
                    }
                }
            }
        }

        &#element-news-and-downloads__title-4-page{
            background-color: $blue;
            border: 1px solid $blue;
            padding: 0;
            height: auto;
            padding: 0.7rem 0 0 0.7rem;
            h2{
                font-size: 1.1rem;
                color: white;
            }
        }
    }
    .tab-pane{
        &:not(.active) {
            display: none;
        }
        .card-body{
            padding: 0.5rem;
            .list-group{
                a{
                    h6{
                        font-size: 1rem;
                        font-weight: 600;
                    }
                    p{
                        font-size: 0.9rem;
                        line-height: 1.2;
                    }
                }
            }
        }
    }
    // #element-news-and-downloads__title-4-page{
        
    // }
}

