p,ol,ul,dl{
    margin: 0 !important;
}
body{
    background-color: #fff;
}
.content-wrapper{
    max-width: 1366px;
}

.content-row-1, .content-row-2, .content-row-3, .content-row-4{
    @media screen and (min-width:$md){
        .content-row-1__left,.content-row-1__right,.content-row-2__left,.content-row-2__right{
            padding: 0;
        }
        .content-row-1__left,.content-row-2__left{
            margin-right: 0.3rem;
        }
        .content-row-1__right,.content-row-2__right{
            margin-left: 0.3rem;
        }
    }
}

// .g-thumbnail.cursor-pointer:hover::after {
//     content: attr(data-title);
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%,-50%);
//     color: #fff;
//     background-color: $blue;
//     font-size: 15px;
//     padding: 2px 5px;
//     border-radius:3px;
// }

.cursor-pointer{
    cursor: pointer;
}