header{
    width: 100%;
    background-color: white;
    .nav-wrapper{
        cursor: pointer;
        max-width: 1366px;
    }
    .navbar {
        padding: 0.6rem 1rem;
    }
    
    nav.top-logo-bar{
        flex-wrap: nowrap;
        justify-content: center;
        
        .navbar-brand{
            &.mobile-menu{
                display: block;
                @media screen and (min-width:$sm) {
                    display: none;
                }
            }
            &.pc-menu{
                display: none;
                @media screen and (min-width:$sm) {
                    display: block;
                }
            }
            
            .site-logo{
                pointer-events: none;
                height: 3.5rem;
                @media screen and (min-width:$sm) {
                    height: 5.5rem;
                }
                @media screen and (min-width:$md) {
                    height: 5.5rem;
                    margin-left: 3rem;
                }
                @media screen and (min-width:$lg) {
                    height: 7.5rem;
                    margin-left: 0rem;
                }
            }
            p{
                pointer-events: none;
                color: $blue;
                line-height: 1.5rem;
                span{
                    font-size: 0.8rem;
                    color: $red;
                    font-weight: bold;
                    &.department{
                        font-size: 1rem;
                    }
                    @media screen and (min-width:$sm) {
                        font-size: 0.9rem;
                        &.department{
                            font-size: 1.1rem;
                        }
                    }
                    @media screen and (min-width:$md) {
                        font-size: 1rem;
                        &.department{
                            font-size: 1.2rem;
                        }
                    }
                    @media screen and (min-width:$lg) {
                        font-size: 1.1rem;
                        &.department{
                            font-size: 1.3rem;
                        }
                    }
                }
            }
            .nepal-flag-animated{
                pointer-events: none;
                height: 3.5rem;
                margin: 0 0 0 1rem;
                
                @media screen and (min-width:$sm) {
                    height: 5.5rem;
                }
                @media screen and (min-width:$md) {
                    height: 5.5rem;
                    margin-right: 3rem;
                }
                @media screen and (min-width:$lg) {
                    height: 7.5rem;
                    margin-right: 0rem;
                    // margin-left: 7rem;
                }
            }
        }
        .small-menu{
            position: absolute;
            top: 0;
            right: 2rem;
            margin-top: 1rem;
            
            ul{
                .btn-sm{
                    padding: 0.21rem 0.53em;
                    line-height: 1;
                }
                li{
                    margin: 0 0.1rem;
                    font-size: 0.7rem;
                }
            }
        }
    }
}
nav#main-navbar{
    .nav-wrapper{
        width: 1366px;
    }
    .navbar-nav{
        font-size: 1rem;

        .nav-item{
            padding: 0 1rem;

            a{
                &:hover{
                    background-color: #fff;
                    color: $blue !important;
                }
            }

            .dropdown-menu{
                background-color: $blue;

                .dropdown-item{
                    color: #fff;
                    border-bottom: 1px solid #fff;
                    
                    &:nth-child(1){
                        border-top: 1px solid #fff;
                    }

                    &:hover{
                        color: $blue !important;
                        background-color: #fff !important;
                    }
                }
            }
        }
    }
}