.element-about{
    width: 100%;
    height: 100%;
    
    .card-header{
        border: 1px solid $blue;
        background-color: $blue;
        border-radius: 0;
        padding: 0;
        height: auto;
        padding: 0.7rem 0 0 0.7rem;
        color: white;

        h2{
            font-size: 0.9rem;
            font-weight: 600;
            @media screen and (min-width:$md) {
                font-size: 1.1rem;
            }
        }
    }
    .card-body{
        &#element-about__content{
            text-align: justify;
        }
        &#element-about__content-full{
            text-align: justify;
        }
    }
}