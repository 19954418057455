.element-image_gallary{
    width: 100%;
    height: 100%;
    border-top: 1px solid #cecfd491;

    .card{
        .card-header{
            h2{
                font-size: 1.1rem;
                font-weight: 600;
                color: $blue;
                @media screen and (min-width:$md) {
                    font-size: 1.4rem;
                }
            }
            &#element-image_gallary__title-4-page{
                background-color: $blue;
                border: 1px solid $blue;
                padding: 0;
                height: auto;
                padding: 0.7rem 0 0 0.7rem;
                h2{
                    font-size: 1.1rem;
                    color: white;
                }
            }
        }
        .card-body{
            .card{
                // width: 300px;
                .card-body{
                    overflow: hidden;
                    img{
                        height: 200px;
                        transition-duration: 0.5s;
                        &.galleryThumbnail:hover{
                            transform: scale(1.2);
                            z-index: 0;
                        }
                    }
                }
            }
        }
    }
}